// src/pages/About.js
import React,{ useState } from 'react';
import BreadCrumb from '../components/common/BreadCrumb';
// import DesigningServices from '../components/Designing/DesigningServices';
import CtaButton from '../components/common/CtaButton';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Ghostwritingbg from '../images/Ghostwritingbg.png'; // Import the image
import FAQSection from '../components/Designing/DesignFaq';
import ContactForm from '../components/common/ContactForm1';
import SecondSection from '../components/home/SecondSection';
import DesignProcess from '../components/common/DesignProcess';
import ImageRightBanner from '../components/common/ImageRightText';
import ImageLeftBanner from '../components/common/ImageLeftText';
import FictionImage1 from '../images/rap.jpg';
import fictionwhychoose from '../images/fictionwhychoose.png';
import ghostWritingImage from '../images/Ghost Writing.png';
import designingImage from '../images/Designing.png';
import bookEditingImage from '../images/book edit.png';
import designprocess1 from '../images/design-process-1.png';
import designprocess2 from '../images/design-process-2.png';
import designprocess3 from '../images/design-process-3.png';
import designprocess4 from '../images/design-process-4.png';
import PopupForm1 from '../components/common/PopupForm';
import { Helmet } from 'react-helmet-async';

// ******************************** Start SecondSection ******************************

const services = [
  {
    title: "The Concept Alchemist",
    image: ghostWritingImage, // Update this if you have a specific image
    description: "Unleash your creative potential with The Concept Alchemist. We develop core concepts, craft compelling narratives, and provide a detailed project roadmap to guide your musical journey."
  },
  {
    title: "The Verse Architect",
    image: designingImage, // Update this if you have a specific image
    description: "Do you need powerful verses? The Verse Architect delivers high-quality lyrics tailored to your specific needs and style, elevating your artistic expression."
  },
  {
    title: "The Hook Maestro",
    image: bookEditingImage, // Update this if you have a specific image
    description: "Craft irresistible hooks using The Hook Maestro. Our team specializes in creating catchy and unforgettable choruses that will have your audience singing along and craving more."
  }
];


// ******************************** End SecondSection ******************************

// ******************************** Start Design Process Props ******************************

const heading = {
  title: (
    <>
   Our Rap 
     <br />
    </>
  ),
  highlight: "Ghostwriting Process"
};
// const description = "At AMZ Book Publishings, our medical ghostwriting services are designed to make your research process easier and more effective.";

const steps = [
  {
    image: designprocess1,
    alt: "Initial Discovery ",
    title: "Initial Discovery ",
    description: "We begin by gaining a deep understanding of you, and the message of your audience through a comprehensive discovery process. "
  },
  {
    image: designprocess2,
    alt: "Creative Writing ",
    title: "Creative Writing ",
    description: "Our skilled writers create engaging, high-quality lyrics that blend memorable melodies, impactful verses, and a smooth rhythm tailored to your distinct sound. "
  },
  {
    image: designprocess3,
    alt: "Revision & Feedback ",
    title: "Revision & Feedback ",
    description: "Collaboration is essential to us. We offer multiple revision cycles, incorporating your input to guarantee a final product that meets and exceeds your creative vision. "
  },
  {
    image: designprocess4,
    alt: "Final Delivery",
    title: "Final Delivery ",
    description: "You'll receive professionally crafted, studio-ready, giving you the freedom to focus on bringing your music to life and achieving your artistic aspirations."
  }
];



// ******************************** End Design Process Props ******************************

// ******************************** Start FAQ Section ******************************
const faqData = [
  {
    question: "Can you find me a ghostwriter who has specific experience/knows about law?",
    answer: "Absolutely! At AMZ Book Publishings, we make sure to pair you with a legal ghostwriter who has the expertise and background in law that fits your project. Whether it’s contracts, legal briefs, or academic legal writing, we have a team of professionals who know their way around legal jargon and concepts."
  },
  {
    question: "If I invest in your services, can you ensure my legal ghostwriting piece is authentic?",
    answer: "Yes, authenticity is key. Our legal ghostwriters work closely with you to ensure your unique voice and ideas shine through while still adhering to all the legal details. We take the time to understand your needs so that the final piece is 100% original, personalized, and tailored to your specific goals."
  },
  {
    question: "Are your ghostwriting services confidential?",
    answer: "Absolutely! Confidentiality is a top priority for us. We treat every project with the utmost discretion, so your documents, ideas, and personal information are completely secure. Your privacy is just as important to us as delivering quality work."
  },
  {
    question: "What kind of legal documents can I hire a ghostwriter for?",
    answer: "You can hire us for a wide range of legal documents, including contracts, legal memos, case summaries, briefs, research papers, and even full legal textbooks. Whatever you need, our skilled team can handle it!"
  },
  {
    question: "Is ghostwriting ethical?",
    answer: "Yes, ghostwriting is completely ethical. It is a collaborative process where you provide the direction, and the ghostwriter provides the expertise. You are still the mastermind behind the project and our job is to help you bring it to life in a professional and polished way."
  }
];

// ******************************** End FAQ Section ******************************

const LegalGhostwriting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      {/* -------------------Start Banner section---------------------- */}
      <Helmet>
            <title>Rap/Hip Hop Ghostwriting Services for Musicians</title>
            <meta name="description" content="Need Rap/Hip Hop ghostwriter? We offer professional Rap ghostwriting services at an affordable price. Contact us to hire expert writers for your project." />
            <link rel="canonical" href="https://amzbookpublishing.net/rap-ghostwriting-services " />
            <meta name="robots" content="index, follow" />
            {/* <meta name="robots" content="index, follow" /> */}
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Rap/Hip Hop Ghostwriting Services for Musicians" />
            <meta property="og:description" content="Need Rap/Hip Hop ghostwriter? We offer professional Rap ghostwriting services at an affordable price. Contact us to hire expert writers for your project." />
            <meta property="og:url" content="https://amzbookpublishing.net/rap-ghostwriting-services" />
            <meta property="og:site_name" content="AMZBookPublishing" />
            <meta property="article:publisher" content="https://www.facebook.com/AmzBookPublishingUS" />
            <meta property="article:modified_time" content="2024-08-23T07:03:42+00:00" />

      </Helmet>
      <Header />
      <BreadCrumb
        title="Hip Hop or Rap Ghostwriting Services"
        desc={
          <>
   AMZ Book Publishing takes pride in being the go-to Rap Ghostwriting Agency in the USA. We deliver hard-hitting lyrics, innovative flows, and authentic storytelling tailored to your voice.
          </>
        }
        buttonText="Get a Quote"
        onClick={openModal} // Call openModal on button click
        backgroundImage={Ghostwritingbg} // Pass the image URL as a prop
      />

      {/* Popup Form */}
      <PopupForm1 isOpen={isModalOpen} closeModal={closeModal} />
    
   
{/* -------------------End Banner section---------------------- */}
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<ImageTextBanner 
    contentItems={contentItems} 
/>
</div> */}


<div style={{ padding: '30px 0px 100px 0px' }}>
<ImageRightBanner
    heading="Professional Rap Ghostwriting "
    subHeading="Services For Musicians"
    description={
        <>
Our professional rap/hip hop ghostwriter partners with artists to turn fresh concepts into exciting rap melodies. If you're an artist from beginner to pro our expert team delivers lyrics that match your artistic style directly. Our team of expert writers works across any rap style to create intriguing premium lyrics that connect with listeners and enhance your music's success. 
          <br/><br/>
          We collaborate closely with you to understand your themes and artistic direction, ensuring every verse and hook feels authentic. With full confidentiality and ownership of the content, you can take full credit for your tracks. Let our professional rap ghostwriting services help you create memorable music that stands out in the industry.
        </>
    }
    imageSrc={FictionImage1}
    objectfit="contain"
    imageHeight="400px"  // Set height here
    imageWidth="100%"     // Set width here
    borderRadius="30px"
/>
</div>

{/*****************  SERVICE Section *****************/}
<div>
<SecondSection 
        buttonLabel="S e r v i c e s"
        heading1={ <>
        Top-Notch  </>}
        subHeading={<>
         Rap/Hip Hop   <br/>
         Ghostwriting </>}
        heading2="Services"
        paragraph="Tired of creative blocks and generic lyrics? Our specialized services – The Concept Alchemist, The Verse Architect, and The Hook Maestro – will ignite your artistry and elevate your music."
        services={services}
      />
      </div>
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<SecondSection/>
</div> */}
<DesignProcess 
        heading={heading}
        // description={description}
        steps={steps}
      />
<div style={{ padding: '0px 0px 50px 0px' }}>
<ImageLeftBanner
    heading="What Makes Us Stand Out?"
    subHeading=""
    description={
      <>
Our team of rap ghostwriters takes pride in providing quick and top-notch lyric writing services to meet tight deadlines in the music industry. We understand the need for speed without compromising quality. Our streamlined process ensures on-time delivery to keep your projects flowing smoothly. 
         <br/><br/>
         We offer affordable rap song writer service packages without compromising the high standard of work you deserve, tailored to your budget. Whether you need help with an idea or a completely original creation, we are dedicated to bringing your vision to fruition through close collaboration from brainstorming to the final product.
      </>
  }
    imageSrc={fictionwhychoose}
    imageHeight=""        // Set height here
    imageWidth=""          // Set width here
    objectfit="cover"          // Set object-fit here
    borderRadius="15px"        // Set border radius here
/>
</div>
<CtaButton/>
         
          <div>
      <FAQSection faqData={faqData} />
    </div>
         <ContactForm/>

      <Footer/>
    </div>
  );
};
export default LegalGhostwriting;


