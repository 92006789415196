// src/pages/About.js
import React,{ useState } from 'react';
import BreadCrumb from '../components/common/BreadCrumb';
// import DesigningServices from '../components/Designing/DesigningServices';
import CtaButton from '../components/common/CtaButton';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Ghostwritingbg from '../images/Ghostwritingbg.png'; // Import the image
import FAQSection from '../components/Designing/DesignFaq';
import ContactForm from '../components/common/ContactForm1';
import SecondSection from '../components/home/SecondSection';
import DesignProcess from '../components/common/DesignProcess';
import ImageRightBanner from '../components/common/ImageRightText';
import ImageLeftBanner from '../components/common/ImageLeftText';
import FictionImage1 from '../images/Medical1.png';
import fictionwhychoose from '../images/fictionwhychoose.png';
import ghostWritingImage from '../images/Ghost Writing.png';
import designingImage from '../images/Designing.png';
import bookEditingImage from '../images/book edit.png';
import designprocess1 from '../images/design-process-1.png';
import designprocess2 from '../images/design-process-2.png';
import designprocess3 from '../images/design-process-3.png';
import designprocess4 from '../images/design-process-4.png';
import PopupForm1 from '../components/common/PopupForm';
import { Helmet } from 'react-helmet-async';

// ******************************** Start SecondSection ******************************

const services = [
  {
    title: "Clinical Research Papers",
    image: ghostWritingImage,
    description: "Need help turning your data into a polished research paper? Our team of medical ghostwriters specializes in creating clear and impactful clinical research papers that get your findings published."
  },
  {
    title: "Medical Textbooks and Guides",
    image: designingImage,
    description: "Whether you are an expert looking to share your knowledge or a professor building educational materials, we help create well-structured, easy-to-understand medical textbooks and guides."
  },
  {
    title: "Journal Articles and Reviews",
    image: bookEditingImage,
    description: "As your trusted medical ghostwriter, we craft professional journal articles and reviews that reflect your expertise and elevate your contributions in the medical field."
  }
];

// ******************************** End SecondSection ******************************

// ******************************** Start Design Process Props ******************************

const heading = {
  title: (
    <>
     Your Journey to
     {/* <br /> */}
    </>
  ),
  highlight: "Medical Publishing"
};
const description = "At AMZ Book Publishings, our medical ghostwriting services are designed to make your research process easier and more effective.";

const steps = [
  {
    image: designprocess1,
    alt: "Study Design & Concept",
    title: "Study Design & Concept",
    description: "We assist with creating medical and scientific research studies, formulating hypotheses, developing a strong theoretical background, conducting statistical evaluations, and presenting results in text or presentation form."
  },
  {
    image: designprocess2,
    alt: "Statistical Evaluation",
    title: "Statistical Evaluation",
    description: "We handle all aspects of data gathering and interpretation, providing clear analyses and professional presentations to back up your findings."
  },
  {
    image: designprocess3,
    alt: "Manuscript Creation",
    title: "Manuscript Creation",
    description: "Whether it’s for publication or lecturing, we specialize in subject-specific research, literature reviews, abstracts, and crafting manuscripts that are polished and ready for your audience."
  },
  {
    image: designprocess4,
    alt: "Article Editing & Proofreading",
    title: "Article Editing & Proofreading",
    description: "We refine your medical articles, improving language, grammar, and clarity while ensuring your work meets international publishing standards and the quality expected of native-English speakers."
  }
];



// ******************************** End Design Process Props ******************************

// ******************************** Start FAQ Section ******************************
const faqData = [
  {
    question: "What exactly is ghostwriting in pharmacology?",
    answer: "When you think of ghostwriting, celebrity memoirs might come to mind, but it is also widely used in the pharmaceutical world. In pharmacology, ghostwriting involves researchers or their consultants drafting research papers, articles, and other key documents for pharmaceutical professionals, ensuring the right expertise is applied without the need for direct authorship."
  },
  {
    question: "What if I am not happy with the final draft?",
    answer: "If you are not completely satisfied with the final draft, just let us know. We offer unlimited revisions because we value your input and want to make sure everything meets your expectations. Simply share your concerns, and once we understand your needs, we will revise the draft and make it right. We are with you every step of the way to ensure you love the final outcome."
  },
  {
    question: "Is your medical ghostwriting service confidential?",
    answer: "Absolutely! We understand how important confidentiality is. Unlike some unprofessional ghostwriters who might use your project details for promotion, we keep everything strictly confidential. Your work remains entirely yours, and we follow professional editing and publishing standards, so no excerpts are shared without your permission. Your trust is our priority."
  },
  {
    question: "How do I know if medical ghostwriting is right for my project?",
    answer: "If you are a busy healthcare professional, researcher, or pharmaceutical expert looking to publish but don't have the time to dedicate to writing, medical ghostwriting might be the perfect solution. Our experienced medical ghostwriters can help bring your ideas and research to life, ensuring your work is presented clearly and professionally without compromising quality."
  },
  {
    question: "Can I be credited as the author of a ghostwritten medical paper?",
    answer: "Yes, absolutely! Medical ghostwriting allows you to maintain full ownership of your work, meaning you can be credited as the sole or primary author of the paper. Our role is to assist in crafting the content while you retain complete authorship and control over the final product."
  }
];




// ******************************** End FAQ Section ******************************

const MedicalGhostwriting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      {/* -------------------Start Banner section---------------------- */}
<Helmet>

    <title>Medical Ghostwriting Services by Experienced Medical Writers</title>
    <meta name="description" content="Need a Medical ghostwriter? We offer professional Medical ghostwriting services at an affordable price. Contact us to hire writers remotely for your project." />
    <link rel="canonical" href="https://amzbookpublishing.net/professional-medical-ghostwriting-services " />
    <meta name="robots" content="index, follow" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="Medical Ghostwriting Services by Experienced Medical Writers" />
    <meta property="og:description" content="Need a Medical ghostwriter? We offer professional Medical ghostwriting services at an affordable price. Contact us to hire writers remotely for your project." />
    <meta property="og:url" content="https://amzbookpublishing.net/professional-medical-ghostwriting-services" />
    <meta property="og:site_name" content="AMZBookPublishing" />
    <meta property="article:publisher" content="https://www.facebook.com/AmzBookPublishingUS" />
    <meta property="article:modified_time" content="2024-08-23T07:03:25+00:00" />
    
</Helmet>
      <Header />
      <BreadCrumb
        title="Medical Ghostwriting"
        desc={
          <>
          Let us help you showcase your expertise with our medical ghostwriting services and make a lasting impact in the academic world! Whether you are looking to publish groundbreaking studies or craft insightful articles, we have got you covered.

          </>
        }
        buttonText="Get a Quote"
        onClick={openModal} // Call openModal on button click
        backgroundImage={Ghostwritingbg} // Pass the image URL as a prop
      />

      {/* Popup Form */}
      <PopupForm1 isOpen={isModalOpen} closeModal={closeModal} />
    
   
{/* -------------------End Banner section---------------------- */}
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<ImageTextBanner 
    contentItems={contentItems} 
/>
</div> */}


<div style={{ padding: '30px 0px 100px 0px' }}>
<ImageRightBanner
    heading="Medical "
    subHeading="Ghostwriting"
    description={
        <>
        Many physicians, surgeons, and medical experts face the challenge of staying updated on the latest medical advancements while juggling patient care and research responsibilities. Keeping up with the demands of medical studies and publishing can be overwhelming.
           <br/><br/>
           That is where our medical ghostwriting services come in. We help bridge the gap by creating high-quality, scientifically accurate articles that reflect the newest medical research. From groundbreaking studies to diverse medical topics, we ensure your content is ethical, plagiarism-free, and aligned with the latest advancements in the field.
        </>
    }
    imageSrc={FictionImage1}
    objectfit="contain"
    imageHeight="400px"  // Set height here
    imageWidth="100%"     // Set width here
    borderRadius="30px"
/>
</div>

{/*****************  SERVICE Section *****************/}
<div>
<SecondSection 
        buttonLabel="S e r v i c e s"
        heading1={ <>
        Expert  </>}
        subHeading={<>
         Medical  <br/>
         Writing </>}
        heading2="Services"
        paragraph="Partner with our skilled medical ghostwriters, including experienced MDs, NDs, and scientific writers, to craft high-quality, well-researched papers that make an impact. We will help you get your ideas published with precision and professionalism!"
        services={services}
      />
      </div>
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<SecondSection/>
</div> */}
<DesignProcess 
        heading={heading}
        description={description}
        steps={steps}
      />
<div style={{ padding: '0px 0px 50px 0px' }}>
<ImageLeftBanner
    heading="Why Choose Us"
    subHeading=""
    description={
      <>
      Our medical ghostwriting team is composed of seasoned professionals and specialists across various medical fields. They understand the responsibility and precision your work demands because they hold the same high standards. 
         <br/><br/>
         We ensure that every manuscript is crafted by an expert in the relevant field, guaranteeing original, high-quality content. In addition, we not only offer top-notch but affordable ghostwriting services, so you can access professional support without breaking the bank.
      </>
  }
    imageSrc={fictionwhychoose}
    imageHeight=""        // Set height here
    imageWidth=""          // Set width here
    objectfit="cover"          // Set object-fit here
    borderRadius="15px"        // Set border radius here
/>
</div>
<CtaButton/>
         
          <div>
      <FAQSection faqData={faqData} />
    </div>
         <ContactForm/>

      <Footer/>
    </div>
  );
};
export default MedicalGhostwriting;


