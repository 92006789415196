// src/pages/About.js
import React,{ useState } from 'react';
import BreadCrumb from '../components/common/BreadCrumb';
// import DesigningServices from '../components/Designing/DesigningServices';
import CtaButton from '../components/common/CtaButton';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Ghostwritingbg from '../images/Ghostwritingbg.png'; // Import the image
import FAQSection from '../components/Designing/DesignFaq';
import ContactForm from '../components/common/ContactForm1';
import SecondSection from '../components/home/SecondSection';
import DesignProcess from '../components/common/DesignProcess';
import ImageRightBanner from '../components/common/ImageRightText';
import ImageLeftBanner from '../components/common/ImageLeftText';
import ghostWritingImage from '../images/Ghost Writing.png';
import fictionwhychoose from '../images/fictionwhychoose.png';
import NovelExpert from '../images/NovelExpert.jpg';
import designingImage from '../images/Designing.png';
import bookEditingImage from '../images/book edit.png';
import designprocess1 from '../images/design-process-1.png';
import designprocess2 from '../images/design-process-2.png';
import designprocess3 from '../images/design-process-3.png';
import designprocess4 from '../images/design-process-4.png';
import PopupForm1 from '../components/common/PopupForm';
import { Helmet } from 'react-helmet-async';

// ******************************** Start SecondSection ******************************

const services = [
  {
    title: "Personalized Storytelling",
    image: ghostWritingImage, // Update this if you have a specific image
    description: "The service emphasizes creating a unique and unforgettable narrative tailored specifically to the client's vision. This suggests a collaborative approach that values individual ideas and preferences."
  },
  {
    title: "Proven Success and Experience",
    image: designingImage, // Update this if you have a specific image
    description: "The content highlights the team's track record of creating successful novels that resonate with readers. This emphasizes their expertise and experience in crafting compelling stories across different genres."
  },
  {
    title: "Bringing Your Vision to Life",
    image: bookEditingImage, // Update this if you have a specific image
    description: "The service promises to help clients bring their storytelling aspirations to fruition. This suggests that the team will work closely with the client to develop and refine their novel, helping them achieve their literary goals."
  }
];


// ******************************** End SecondSection ******************************

// ******************************** Start Design Process Props ******************************

const heading = {
  title: (
    <>
    Why Should You Choose Our Novel 
     <br />
    </>
  ),
  highlight: "Ghostwriting Services?"
};
// const description = "At AMZ Book Publishings, our medical ghostwriting services are designed to make your research process easier and more effective.";

const steps = [
  {
    image: designprocess1,
    alt: "Client Focus & Expertise",
    title: "Client Focus & Expertise",
    description: "Our main priority is delivering superior work to our clients, and our team of professional novel ghostwriters is always brainstorming the most effective approach."
  },
  {
    image: designprocess2,
    alt: "Timeliness & Efficiency",
    title: "Timeliness & Efficiency",
    description: "We guarantee punctual delivery by leveraging our team's proficiency, efficiency, and readiness. Our commitment to meeting deadlines ensures that we consistently deliver ahead of schedule."
  },
  {
    image: designprocess3,
    alt: "Quality & Satisfaction",
    title: "Quality & Satisfaction",
    description: "Bring your writing vision to life with our professional novel writing services. We stand behind the quality of every book we create, ensuring your complete satisfaction."
  },
  {
    image: designprocess4,
    alt: "Ownership & Control",
    title: "Ownership & Control",
    description: "We give you complete and exclusive ownership of all the written material, including copyright, so you have full control over its publication and distribution."
  }
];



// ******************************** End Design Process Props ******************************

// ******************************** Start FAQ Section ******************************
const faqData = [
  {
    question: "Can you find me a ghostwriter who has specific experience/knows about law?",
    answer: "Absolutely! At AMZ Book Publishings, we make sure to pair you with a legal ghostwriter who has the expertise and background in law that fits your project. Whether it’s contracts, legal briefs, or academic legal writing, we have a team of professionals who know their way around legal jargon and concepts."
  },
  {
    question: "If I invest in your services, can you ensure my legal ghostwriting piece is authentic?",
    answer: "Yes, authenticity is key. Our legal ghostwriters work closely with you to ensure your unique voice and ideas shine through while still adhering to all the legal details. We take the time to understand your needs so that the final piece is 100% original, personalized, and tailored to your specific goals."
  },
  {
    question: "Are your ghostwriting services confidential?",
    answer: "Absolutely! Confidentiality is a top priority for us. We treat every project with the utmost discretion, so your documents, ideas, and personal information are completely secure. Your privacy is just as important to us as delivering quality work."
  },
  {
    question: "What kind of legal documents can I hire a ghostwriter for?",
    answer: "You can hire us for a wide range of legal documents, including contracts, legal memos, case summaries, briefs, research papers, and even full legal textbooks. Whatever you need, our skilled team can handle it!"
  },
  {
    question: "Is ghostwriting ethical?",
    answer: "Yes, ghostwriting is completely ethical. It is a collaborative process where you provide the direction, and the ghostwriter provides the expertise. You are still the mastermind behind the project and our job is to help you bring it to life in a professional and polished way."
  }
];

// ******************************** End FAQ Section ******************************

const LegalGhostwriting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      {/* -------------------Start Banner section---------------------- */}
      <Helmet>
                <title>Professional Novel Writing Services for Aspiring Authors</title>
                <meta name="description" content="Need a Novel ghostwriter? We offer professional Novel ghostwriting services at an affordable price. Contact us to hire expert writers remotely for your project." />
                <link rel="canonical" href="https://amzbookpublishing.net/professional-novel-writing-services " />
                <meta name="robots" content="index, follow" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Professional Novel Writing Services for Aspiring Authors" />
                <meta property="og:description" content="Need a Novel ghostwriter? We offer professional Novel ghostwriting services at an affordable price. Contact us to hire expert writers remotely for your project." />
                <meta property="og:url" content="https://amzbookpublishing.net/professional-novel-writing-services" />
                <meta property="og:site_name" content="AMZBookPublishing" />
                <meta property="article:publisher" content="https://www.facebook.com/AmzBookPublishingUS" />
                <meta property="article:modified_time" content="2024-08-23T07:20:58+00:00" />



      </Helmet>
      <Header />
      <BreadCrumb
        title="Professional Novel Ghostwriting Services "
        desc={
          <>
We're dedicated to delivering exceptional results and crafting compelling narratives that captivate readers. Our skilled novel ghostwriters have a talent for elevating everyday tales into unforgettable literary masterpieces.
          </>
        }
        buttonText="Get a Quote"
        onClick={openModal} // Call openModal on button click
        backgroundImage={Ghostwritingbg} // Pass the image URL as a prop
      />

      {/* Popup Form */}
      <PopupForm1 isOpen={isModalOpen} closeModal={closeModal} />
    
   
{/* -------------------End Banner section---------------------- */}
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<ImageTextBanner 
    contentItems={contentItems} 
/>
</div> */}


<div style={{ padding: '30px 0px 100px 0px' }}>
<ImageRightBanner
    heading="Best Novel Ghostwriting "
    subHeading="Agency in the USA "
    description={
        <>
Novels must contain compelling concepts that engage readers. To us, ghostwriting novels involves creating a story of a particular length that portrays the characters' behaviors, dialogue, and inner reflections. We specialize in crafting engaging and original novels that feature exceptional dialogues, captivating characters, and unique        <br/><br/>
At AMZ book publishing, our primary objective is to craft a compelling narrative that captivates the reader. To achieve this, we collaborate with the author to understand their vision and the core message they aim to convey. With a clear understanding of their requirements, we create original content that meets their unique needs. This process involves meticulous attention to detail and a significant investment of time, ensuring that the final product exceeds the author's expectations.
        </>
    }
    imageSrc={NovelExpert}
    objectfit="contain"
    imageHeight="400px"  // Set height here
    imageWidth="100%"     // Set width here
    borderRadius="30px"
/>
</div>

{/*****************  SERVICE Section *****************/}
<div>
<SecondSection 
        buttonLabel="S e r v i c e s"
        heading1={ <>
        Top-Notch  </>}
        subHeading={<>
       Professional Novel  <br/>
         Ghostwriting </>}
        heading2="Services"
        paragraph=" Our group of talented fiction authors is prepared to work together with you to craft a compelling piece of literature that will make a lasting impression."
        services={services}
      />
      </div>
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<SecondSection/>
</div> */}
<DesignProcess 
        heading={heading}
        // description={description}
        steps={steps}
      />
<div style={{ padding: '0px 0px 50px 0px' }}>
<ImageLeftBanner
    heading="Quality Novel Ghostwriting " 
    subHeading="at Affordable Prices"
    description={
      <>
Crafting a novel requires a distinct fusion of artistic talent, perseverance, and narrative expertise. Our team of seasoned novelists brings this rare combination to the table, empowering us to transform your literary concept into a reality. From refining a gripping storyline to breathing life into memorable characters, we offer all-encompassing guidance. Our specialty lies in weaving immersive tales that transport audiences to diverse realms and periods, while skillfully crafting characters that resonate deeply and bond with readers.
         <br/><br/>
         We are an affordable novel ghostwriting company that values making our services easily accessible while still maintaining high standards dedicated to delving into a wide range of topics and styles to make your novel unique. You can rely on our skilled writers to turn your concepts into a captivating and well-crafted novel that connects with your intended readers. Reach out to us now for a complimentary consultation and let's start this thrilling literary venture together.
      </>
  }
    imageSrc={fictionwhychoose}
    imageHeight=""        // Set height here
    imageWidth=""          // Set width here
    objectfit="cover"          // Set object-fit here
    borderRadius="15px"        // Set border radius here
/>
</div>
<CtaButton/>
         
          <div>
      <FAQSection faqData={faqData} />
    </div>
         <ContactForm/>

      <Footer/>
    </div>
  );
};
export default LegalGhostwriting;


