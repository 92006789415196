// src/pages/About.js
import React,{ useState } from 'react';
import BreadCrumb from '../components/common/BreadCrumb';
// import DesigningServices from '../components/Designing/DesigningServices';
import CtaButton from '../components/common/CtaButton';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Ghostwritingbg from '../images/Ghostwritingbg.png'; // Import the image
import FAQSection from '../components/Designing/DesignFaq';
import ContactForm from '../components/common/ContactForm1';
import SecondSection from '../components/home/SecondSection';
import DesignProcess from '../components/common/DesignProcess';
import ImageRightBanner from '../components/common/ImageRightText';
import ImageLeftBanner from '../components/common/ImageLeftText';
import ghostWritingImage from '../images/Ghost Writing.png';
import fictionwhychoose from '../images/fictionwhychoose.png';
import ScreenPlay from '../images/health-wa.jpg';
import designingImage from '../images/Designing.png';
import bookEditingImage from '../images/book edit.png';
import designprocess1 from '../images/design-process-1.png';
import designprocess2 from '../images/design-process-2.png';
import designprocess3 from '../images/design-process-3.png';
import designprocess4 from '../images/design-process-4.png';
import PopupForm1 from '../components/common/PopupForm';
import { Helmet } from 'react-helmet-async';

// ******************************** Start SecondSection ******************************

const services = [
  {
    title: "Focus on Factual Accuracy",
    image: ghostWritingImage, // Update this if you have a specific image
    description: "At AMZ book publishing, a commitment to verifiable information and precision drives the creation of health and fitness content, setting them apart from competitors who often resort to generic and unoriginal material."
  },
  {
    title: "Commitment to Quality and Affordability",
    image: designingImage, // Update this if you have a specific image
    description: "Dedication to providing top-notch work at reasonable prices remains unwavering, even amidst strict timelines. They ensure that all content, whether verified digitally or manually, meets high standards."
  },
  {
    title: "Proven Success",
    image: bookEditingImage, // Update this if you have a specific image
    description: "Established Credibility with a history of achievement, they have authored multiple top-selling books that have resonated with audiences in the health and wellness industry."
  }
];


// ******************************** End SecondSection ******************************

// ******************************** Start Design Process Props ******************************

const heading = {
  title: (
    <>
  Four Pillars of Excellence 
     <br />
    </>
  ),
  highlight: "at AMZ Book Publishing"
};
// const description = "At AMZ Book Publishings, our medical ghostwriting services are designed to make your research process easier and more effective.";

const steps = [
  {
    image: designprocess1,
    alt: "Emphasis on Idea Development & Planning",
    title: "Emphasis on Idea Development & Planning",
    description: "The primary focus for writers is on developing and refining ideas before writing, involving extensive research to ensure strong foundational concepts."
  },
  {
    image: designprocess2,
    alt: "Fact-Based Approach",
    title: "Fact-Based Approach",
    description: "Unlike many companies that rely on clichés, AMZ book publishing prioritizes factual accuracy and incorporates only relevant and verified information into the content."
  },
  {
    image: designprocess3,
    alt: "Commitment to Quality & Deadlines",
    title: "Commitment to Quality & Deadlines",
    description: "Despite tight deadlines, the company strives to deliver high-quality ghostwriting services within the specified timeframe."
  },
  {
    image: designprocess4,
    alt: "Rigorous Quality Control",
    title: "Rigorous Quality Control",
    description: "All content undergoes both digital and manual checks before publication to ensure accuracy, clarity, and professional presentation."
  }
];



// ******************************** End Design Process Props ******************************

// ******************************** Start FAQ Section ******************************
const faqData = [
  {
    question: "Can you find me a ghostwriter who has specific experience/knows about law?",
    answer: "Absolutely! At AMZ Book Publishings, we make sure to pair you with a legal ghostwriter who has the expertise and background in law that fits your project. Whether it’s contracts, legal briefs, or academic legal writing, we have a team of professionals who know their way around legal jargon and concepts."
  },
  {
    question: "If I invest in your services, can you ensure my legal ghostwriting piece is authentic?",
    answer: "Yes, authenticity is key. Our legal ghostwriters work closely with you to ensure your unique voice and ideas shine through while still adhering to all the legal details. We take the time to understand your needs so that the final piece is 100% original, personalized, and tailored to your specific goals."
  },
  {
    question: "Are your ghostwriting services confidential?",
    answer: "Absolutely! Confidentiality is a top priority for us. We treat every project with the utmost discretion, so your documents, ideas, and personal information are completely secure. Your privacy is just as important to us as delivering quality work."
  },
  {
    question: "What kind of legal documents can I hire a ghostwriter for?",
    answer: "You can hire us for a wide range of legal documents, including contracts, legal memos, case summaries, briefs, research papers, and even full legal textbooks. Whatever you need, our skilled team can handle it!"
  },
  {
    question: "Is ghostwriting ethical?",
    answer: "Yes, ghostwriting is completely ethical. It is a collaborative process where you provide the direction, and the ghostwriter provides the expertise. You are still the mastermind behind the project and our job is to help you bring it to life in a professional and polished way."
  }
];

// ******************************** End FAQ Section ******************************

const LegalGhostwriting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      {/* -------------------Start Banner section---------------------- */}
      <Helmet>
            <title>Health and Fitness Ghostwriting Services by Book Writers</title>
            <meta name="description" content="Need a health and fitness ghostwriter? We offer professional health and fitness ghostwriting services at an affordable price. Contact us to hire writers remotely." />
            <link rel="canonical" href="https://amzbookpublishing.net/health-and-fitness-ghostwriting-services " />
            <meta name="robots" content="index, follow" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Health and Fitness Ghostwriting Services by Book Writers" />
            <meta property="og:description" content="Need a health and fitness ghostwriter? We offer professional health and fitness ghostwriting services at an affordable price. Contact us to hire writers remotely." />
            <meta property="og:url" content="https://amzbookpublishing.net/health-and-fitness-ghostwriting-services" />
            <meta property="og:site_name" content="AMZBookPublishing" />
            <meta property="article:publisher" content="https://www.facebook.com/AmzBookPublishingUS" />
            <meta property="article:modified_time" content="2024-08-23T07:24:51+00:00" />

      </Helmet>
      <Header />
      <BreadCrumb
        title="Health And Fitness Ghostwriting Services"
        desc={
          <>
Our health and fitness ghostwriter excel in health-related content, covering topics like weight lifting, yoga, and health coaching. They produce high-quality, professional-grade writing.
          </>
        }
        buttonText="Get a Quote"
        onClick={openModal} // Call openModal on button click
        backgroundImage={Ghostwritingbg} // Pass the image URL as a prop
      />

      {/* Popup Form */}
      <PopupForm1 isOpen={isModalOpen} closeModal={closeModal} />
    
   
{/* -------------------End Banner section---------------------- */}
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<ImageTextBanner 
    contentItems={contentItems} 
/>
</div> */}


<div style={{ padding: '30px 0px 100px 0px' }}>
<ImageRightBanner
    heading="Professional Health And  "
    subHeading="Ghostwriting Services"
    description={
        <>
At AMZ book publishing, our team of expert writers specializes in crafting high-quality content on a range of health-related subjects, from weightlifting and yoga to health coaching. Our primary focus is on elevating the standard of content to help you stand out as a professional author with a polished and engaging health and fitness book. 
           <br/><br/>
           We carefully select ghostwriters who possess a deep understanding of the significance of health in everyday life, recognizing it as a vital habit rather than just a lifestyle choice. As a result, we only work with writers who have a profound knowledge of their subject matter, ensuring that your content is both authoritative and compelling.
        </>
    }
    imageSrc={ScreenPlay}
    objectfit="contain"
    imageHeight="400px"  // Set height here
    imageWidth="100%"     // Set width here
    borderRadius="30px"
/>
</div>

{/*****************  SERVICE Section *****************/}
<div>
<SecondSection 
        buttonLabel="S e r v i c e s"
        heading1={ <>
      Health and    </>}
        subHeading={<>
    Wellness <br/>
         Ghostwriting </>}
        heading2="Services"
        paragraph="A unique approach to health and fitness ghostwriting, Blending Rigorous Research with Creative Storytelling to Deliver Engaging and Informative Content."
        services={services}
      />
      </div>
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<SecondSection/>
</div> */}
<DesignProcess 
        heading={heading}
        // description={description}
        steps={steps}
      />
<div style={{ padding: '0px 0px 50px 0px' }}>
<ImageLeftBanner
    heading="Why Choose Our Health And Fitness "
    subHeading="Ghostwriting Services Agency"
    description={
      <>
Selecting a skilled ghostwriting collaborator is vital to the triumph of your health and fitness publication. Our agency excels in its comprehensive industry knowledge, spanning diverse subjects including nutrition, physical activity, mental wellbeing, and alternative therapies. We employ a meticulous, evidence-based methodology, guaranteeing the precision and trustworthiness of all material through exhaustive investigation and collaboration with specialists. 
         <br/><br/>
         Additionally, we recognize the significance of captivating narratives and collaborate intimately with you to convert intricate concepts into absorbing stories that connect with your intended readership. By prioritizing your creative vision and objectives, we ensure the completed manuscript authenticates your distinctive perspective and authority.
      </>
  }
    imageSrc={fictionwhychoose}
    imageHeight=""        // Set height here
    imageWidth=""          // Set width here
    objectfit="cover"          // Set object-fit here
    borderRadius="15px"        // Set border radius here
/>
</div>
<CtaButton/>
         
          <div>
      <FAQSection faqData={faqData} />
    </div>
         <ContactForm/>

      <Footer/>
    </div>
  );
};
export default LegalGhostwriting;


