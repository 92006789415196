// src/pages/About.js
import React,{ useState } from 'react';
import BreadCrumb from '../components/common/BreadCrumb';
// import DesigningServices from '../components/Designing/DesigningServices';
import CtaButton from '../components/common/CtaButton';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Ghostwritingbg from '../images/Ghostwritingbg.png'; // Import the image
import FAQSection from '../components/Designing/DesignFaq';
import ContactForm from '../components/common/ContactForm1';
import SecondSection from '../components/home/SecondSection';
import DesignProcess from '../components/common/DesignProcess';
import ImageRightBanner from '../components/common/ImageRightText';
import ImageLeftBanner from '../components/common/ImageLeftText';
import ghostWritingImage from '../images/Ghost Writing.png';
import fictionwhychoose from '../images/fictionwhychoose.png';
import BlogExpert from '../images/blogexpert.jpg';
import designingImage from '../images/Designing.png';
import bookEditingImage from '../images/book edit.png';
import designprocess1 from '../images/design-process-1.png';
import designprocess2 from '../images/design-process-2.png';
import designprocess3 from '../images/design-process-3.png';
import designprocess4 from '../images/design-process-4.png';
import PopupForm1 from '../components/common/PopupForm';
import { Helmet } from 'react-helmet-async';

// ******************************** Start SecondSection ******************************

const services = [
  {
    title: "Global Recognition and Unparalleled Quality ",
    image: ghostWritingImage, // Update this if you have a specific image
    description: "AMZ Book Publishing's professional blog writing service is globally recognized for its expertise and high quality. Our exceptional skills and top-notch performance have established us as the leader in customer engagement, brand recognition, sales, and lead generation."
  },
  {
    title: "Expert Blog Management",
    image: designingImage, // Update this if you have a specific image
    description: "Managing a blog can be challenging for some brands and websites. Our ghost blogging services are designed to assist those who struggle with blog management. By taking over the management of their blogs, we aim to alleviate their difficulties and help them make progress towards their objectives."
  },
  {
    title: "Professional Approach",
    image: bookEditingImage, // Update this if you have a specific image
    description: "AMZ book publishing has earned a reputation for delivering exceptional blog writing services due to its meticulous and expert methodology. Our team of skilled blog ghostwriters takes the time to thoroughly research the subject matter and understand the intended audience before crafting high-quality content."
  }
];


// ******************************** End SecondSection ******************************

// ******************************** Start Design Process Props ******************************

const heading = {
  title: (
    <>
    Our Process Of Professional 
     <br />
    </>
  ),
  highlight: "Blog Post Writing Service"
};
// const description = "At AMZ Book Publishings, our medical ghostwriting services are designed to make your research process easier and more effective.";

const steps = [
  {
    image: designprocess1,
    alt: "Initial Meeting and Topic Selection",
    title: "Initial Meeting and Topic Selection",
    description: "During the first meeting with the client, we aim to grasp their objectives, target audience, and intentions. Together, we will decide on a suitable and captivating topic that resonates with the brand’s message."
  },
  {
    image: designprocess2,
    alt: "Investigation and Content Organization",
    title: "Investigation and Content Organization",
    description: "Thorough research will be conducted on the chosen topic to pinpoint relevant trends and keywords. An organized outline will be crafted to structure a coherent and engaging blog post."
  },
  {
    image: designprocess3,
    alt: "Content Development and Composition",
    title: "Content Development and Composition",
    description: "The creation process involves producing informative and captivating content that mirrors the brand's identity. Strategic use of targeted keywords will be integrated for enhanced search engine visibility."
  },
  {
    image: designprocess4,
    alt: "Revision, Proofreading, and Final Submission",
    title: "Revision, Proofreading, and Final Submission",
    description: "After completing the content, a comprehensive review will be carried out to ensure clarity and consistency. Proofreading for errors will be conducted before sharing the content with the client for feedback, leading to the final delivery."
  }
];



// ******************************** End Design Process Props ******************************

// ******************************** Start FAQ Section ******************************
const faqData = [
  {
    question: "Can you find me a ghostwriter who has specific experience/knows about law?",
    answer: "Absolutely! At AMZ Book Publishings, we make sure to pair you with a legal ghostwriter who has the expertise and background in law that fits your project. Whether it’s contracts, legal briefs, or academic legal writing, we have a team of professionals who know their way around legal jargon and concepts."
  },
  {
    question: "If I invest in your services, can you ensure my legal ghostwriting piece is authentic?",
    answer: "Yes, authenticity is key. Our legal ghostwriters work closely with you to ensure your unique voice and ideas shine through while still adhering to all the legal details. We take the time to understand your needs so that the final piece is 100% original, personalized, and tailored to your specific goals."
  },
  {
    question: "Are your ghostwriting services confidential?",
    answer: "Absolutely! Confidentiality is a top priority for us. We treat every project with the utmost discretion, so your documents, ideas, and personal information are completely secure. Your privacy is just as important to us as delivering quality work."
  },
  {
    question: "What kind of legal documents can I hire a ghostwriter for?",
    answer: "You can hire us for a wide range of legal documents, including contracts, legal memos, case summaries, briefs, research papers, and even full legal textbooks. Whatever you need, our skilled team can handle it!"
  },
  {
    question: "Is ghostwriting ethical?",
    answer: "Yes, ghostwriting is completely ethical. It is a collaborative process where you provide the direction, and the ghostwriter provides the expertise. You are still the mastermind behind the project and our job is to help you bring it to life in a professional and polished way."
  }
];

// ******************************** End FAQ Section ******************************

const LegalGhostwriting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      {/* -------------------Start Banner section---------------------- */}
      <Helmet>
            <title>Expert Blog Ghostwriting Services for Engaging Content</title>
            <meta name="description" content="Need a Blog ghostwriter? We offer professional Blog ghostwriting services at an affordable price. Contact us to hire expert bloggers remotely for your project." />
            <link rel="canonical" href="https://amzbookpublishing.net/professional-blog-ghostwriting-services " />
            <meta name="robots" content="index, follow" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Expert Blog Ghostwriting Services for Engaging Content" />
            <meta property="og:description" content="Need a Blog ghostwriter? We offer professional Blog ghostwriting services at an affordable price. Contact us to hire expert bloggers remotely for your project." />
            <meta property="og:url" content="https://amzbookpublishing.net/professional-blog-ghostwriting-services" />
            <meta property="og:site_name" content="AMZBookPublishing" />
            <meta property="article:publisher" content="https://www.facebook.com/AmzBookPublishingUS" />
            <meta property="article:modified_time" content="2024-08-23T07:24:16+00:00" />


      </Helmet>
      <Header />
      <BreadCrumb
        title="Blog Ghostwriting Services"
        desc={
          <>
Our mission is to deliver exceptional blog post writing services that help businesses forge meaningful connections with their target audience, leveraging our expertise to craft compelling narratives that drive engagement and results.
          </>
        }
        buttonText="Get a Quote"
        onClick={openModal} // Call openModal on button click
        backgroundImage={Ghostwritingbg} // Pass the image URL as a prop
      />

      {/* Popup Form */}
      <PopupForm1 isOpen={isModalOpen} closeModal={closeModal} />
    
   
{/* -------------------End Banner section---------------------- */}
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<ImageTextBanner 
    contentItems={contentItems} 
/>
</div> */}


<div style={{ padding: '30px 0px 100px 0px' }}>
<ImageRightBanner
    heading="Professional Blog "
    subHeading="Ghostwriting Services"
    description={
        <>
AMZ Book Publishing provides expert blog writing services with a skilled team dedicated to boosting brand awareness, generating leads, increasing sales, and enhancing customer interaction. We specialize in delivering top-notch ghost blogging services, recognizing that numerous websites and companies may require assistance with maintaining their blog content.       <br/><br/>
Our dedication lies in delivering unparalleled blog writing services, ensuring their high visibility in Google search rankings. Our team of ghost bloggers produces thoroughly researched, captivating, and SEO-optimized content to enhance productivity. We possess strong English writing skills, keep our beauty content updated and well-informed, understand referencing and ghostwriting, and strictly adhere to deadlines.
        </>
    }
    imageSrc={BlogExpert}
    objectfit="contain"
    imageHeight="400px"  // Set height here
    imageWidth="100%"     // Set width here
    borderRadius="30px"
/>
</div>

{/*****************  SERVICE Section *****************/}
<div>
<SecondSection 
        buttonLabel="S e r v i c e s"
        heading1={ <>
        Top-Notch  </>}
        subHeading={<>
       Ghost   <br/>
       Blogging  </>}
        heading2="Services"
        paragraph="Need a professional blog post writing service? Our skilled and seasoned blog ghostwriters offer top-notch blog writing services that are unmatched."
        services={services}
      />
      </div>
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<SecondSection/>
</div> */}
<DesignProcess 
        heading={heading}
        // description={description}
        steps={steps}
      />
<div style={{ padding: '0px 0px 50px 0px' }}>
<ImageLeftBanner
    heading="Why Choose Our Blog "
    subHeading="Ghostwriting Agency In The USA"
    description={
      <>
Selecting the appropriate blog ghostwriting service in the United States is essential for achieving success online. Our agency specializes in producing top-notch, captivating, and effective content designed specifically for the American audience. Skilled writers on our team possess a deep understanding of the preferences of US readers and create compelling stories that truly connect with them.
         <br/><br/>
         Our focus is on using data to guide our strategies, including in-depth research on keywords and competitors to achieve success in SEO. We make sure that your content is in line with your brand's tone and marketing goals. Our offerings go beyond just writing, encompassing content planning, strategy development, and evaluating performance. Select us for a committed team that aims to help you reach your business objectives through engaging blog posts.
      </>
  }
    imageSrc={fictionwhychoose}
    imageHeight=""        // Set height here
    imageWidth=""          // Set width here
    objectfit="cover"          // Set object-fit here
    borderRadius="15px"        // Set border radius here
/>
</div>
<CtaButton/>
         
          <div>
      <FAQSection faqData={faqData} />
    </div>
         <ContactForm/>

      <Footer/>
    </div>
  );
};
export default LegalGhostwriting;


