// src/pages/About.js
import React,{ useState } from 'react';
import BreadCrumb from '../components/common/BreadCrumb';
// import DesigningServices from '../components/Designing/DesigningServices';
import CtaButton from '../components/common/CtaButton';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Ghostwritingbg from '../images/Ghostwritingbg.png'; // Import the image
import FAQSection from '../components/Designing/DesignFaq';
import ContactForm from '../components/common/ContactForm1';
import SecondSection from '../components/home/SecondSection';
import DesignProcess from '../components/common/DesignProcess';
import ImageRightBanner from '../components/common/ImageRightText';
import ImageLeftBanner from '../components/common/ImageLeftText';
import FictionImage1 from '../images/legal1.png';
import fictionwhychoose from '../images/fictionwhychoose.png';
import ghostWritingImage from '../images/Ghost Writing.png';
import designingImage from '../images/Designing.png';
import bookEditingImage from '../images/book edit.png';
import designprocess1 from '../images/design-process-1.png';
import designprocess2 from '../images/design-process-2.png';
import designprocess3 from '../images/design-process-3.png';
import designprocess4 from '../images/design-process-4.png';
import PopupForm1 from '../components/common/PopupForm';
import { Helmet } from 'react-helmet-async';

// ******************************** Start SecondSection ******************************

const services = [
  {
    title: "Legal Articles & Blogs",
    image: ghostWritingImage, // Update this if you have a specific image
    description: "We will take your complex legal concepts and transform them into clear, engaging articles or blogs that resonate with both experts and everyday readers."
  },
  {
    title: "Legal Textbooks & Guides",
    image: designingImage, // Update this if you have a specific image
    description: "Need to publish a comprehensive legal textbook or guide? Our legal ghostwriters have the expertise to craft insightful, well-organized material that hits the mark every time."
  },
  {
    title: "Document Drafting",
    image: bookEditingImage, // Update this if you have a specific image
    description: "Let us handle the fine print! We will draft contracts, memos, or other legal documents that are airtight, precise, and tailored to your specific requirements."
  }
];


// ******************************** End SecondSection ******************************

// ******************************** Start Design Process Props ******************************

const heading = {
  title: (
    <>
    Producing Your Work Of
     <br />
    </>
  ),
  highlight: "Legal Ghostwriting"
};
// const description = "At AMZ Book Publishings, our medical ghostwriting services are designed to make your research process easier and more effective.";

const steps = [
  {
    image: designprocess1,
    alt: "Outlines for Your Legal Work",
    title: "Outlines for Your Legal Work",
    description: "We craft detailed outlines that keep your project on track, guiding you from concept to completion with ease."
  },
  {
    image: designprocess2,
    alt: "Content that Reflects Your Expertise",
    title: "Content that Reflects Your Expertise",
    description: "Our legal ghostwriters work closely with you to ensure your ideas, perspectives, and legal expertise shine through every word."
  },
  {
    image: designprocess3,
    alt: "Meticulous Review & Proofreading",
    title: "Meticulous Review & Proofreading",
    description: "We do not just write; we perfect. Our editors and proofreaders meticulously review your material, ensuring it is flawless from structure to punctuation."
  },
  {
    image: designprocess4,
    alt: "Planning, Organizing & Formatting",
    title: "Planning, Organizing & Formatting",
    description: "From planning the structure to formatting the final document, we take care of every detail, ensuring your legal content is professionally polished and publication-ready."
  }
];



// ******************************** End Design Process Props ******************************

// ******************************** Start FAQ Section ******************************
const faqData = [
  {
    question: "Can you find me a ghostwriter who has specific experience/knows about law?",
    answer: "Absolutely! At AMZ Book Publishings, we make sure to pair you with a legal ghostwriter who has the expertise and background in law that fits your project. Whether it’s contracts, legal briefs, or academic legal writing, we have a team of professionals who know their way around legal jargon and concepts."
  },
  {
    question: "If I invest in your services, can you ensure my legal ghostwriting piece is authentic?",
    answer: "Yes, authenticity is key. Our legal ghostwriters work closely with you to ensure your unique voice and ideas shine through while still adhering to all the legal details. We take the time to understand your needs so that the final piece is 100% original, personalized, and tailored to your specific goals."
  },
  {
    question: "Are your ghostwriting services confidential?",
    answer: "Absolutely! Confidentiality is a top priority for us. We treat every project with the utmost discretion, so your documents, ideas, and personal information are completely secure. Your privacy is just as important to us as delivering quality work."
  },
  {
    question: "What kind of legal documents can I hire a ghostwriter for?",
    answer: "You can hire us for a wide range of legal documents, including contracts, legal memos, case summaries, briefs, research papers, and even full legal textbooks. Whatever you need, our skilled team can handle it!"
  },
  {
    question: "Is ghostwriting ethical?",
    answer: "Yes, ghostwriting is completely ethical. It is a collaborative process where you provide the direction, and the ghostwriter provides the expertise. You are still the mastermind behind the project and our job is to help you bring it to life in a professional and polished way."
  }
];

// ******************************** End FAQ Section ******************************

const LegalGhostwriting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      {/* -------------------Start Banner section---------------------- */}
      <Helmet>
            <title>Legal Ghostwriting Services for Lawyers by Legal Ghostwriters</title>
            <meta name="description" content="Need a Legal ghostwriter? We offer professional Legal ghostwriting services at an affordable price. Contact us to hire expert writers remotely for your project." />
            <link rel="canonical" href="https://amzbookpublishing.net/professional-legal-ghostwriting-services " />
            <meta name="robots" content="index, follow" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:type" content="article" />
            <meta property="og:title" content="Legal Ghostwriting Services for Lawyers by Legal Ghostwriters" />
            <meta property="og:description" content="Need a Legal ghostwriter? We offer professional Legal ghostwriting services at an affordable price. Contact us to hire expert writers remotely for your project." />
            <meta property="og:url" content="https://amzbookpublishing.net/professional-legal-ghostwriting-services" />
            <meta property="og:site_name" content="AMZBookPublishing" />
            <meta property="article:publisher" content="https://www.facebook.com/AmzBookPublishingUS" />
            <meta property="article:modified_time" content="2024-08-23T07:09:16+00:00" />
      </Helmet>
      <Header />
      <BreadCrumb
        title="Legal Ghostwriting"
        desc={
          <>
   Law can feel like a maze and it is easy to feel lost. That is where we come in! As a professional legal ghostwriting service, we are here to lend a hand.

          </>
        }
        buttonText="Get a Quote"
        onClick={openModal} // Call openModal on button click
        backgroundImage={Ghostwritingbg} // Pass the image URL as a prop
      />

      {/* Popup Form */}
      <PopupForm1 isOpen={isModalOpen} closeModal={closeModal} />
    
   
{/* -------------------End Banner section---------------------- */}
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<ImageTextBanner 
    contentItems={contentItems} 
/>
</div> */}


<div style={{ padding: '30px 0px 100px 0px' }}>
<ImageRightBanner
    heading="Legal "
    subHeading="Ghostwriting"
    description={
        <>
Legal writing is a tough balancing act: explaining complex concepts, making persuasive arguments, and ensuring everything is clear and precise. Whether it is influencing courtroom decisions, drafting contracts, or regulations, getting it right is crucial.
           <br/><br/>
           That is where AMZ Book Publishings steps in with our expert legal ghostwriters. We handle the heavy lifting of research and writing while you focus on what you do best, which is practicing law. Our team knows legal jargon, formatting, and structure inside out, so you will always get high-quality, polished work that meets the standards of the legal profession without cutting into your time.
        </>
    }
    imageSrc={FictionImage1}
    objectfit="contain"
    imageHeight="400px"  // Set height here
    imageWidth="100%"     // Set width here
    borderRadius="30px"
/>
</div>

{/*****************  SERVICE Section *****************/}
<div>
<SecondSection 
        buttonLabel="S e r v i c e s"
        heading1={ <>
        Top-Notch  </>}
        subHeading={<>
         Legal   <br/>
         Ghostwriting </>}
        heading2="Services"
        paragraph="Our skilled legal ghostwriters are here to tackle even the most challenging legal topics with ease. We are your go-to team for handling the writing, while you stay focused on practicing law."
        services={services}
      />
      </div>
{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<SecondSection/>
</div> */}
<DesignProcess 
        heading={heading}
        // description={description}
        steps={steps}
      />
<div style={{ padding: '0px 0px 50px 0px' }}>
<ImageLeftBanner
    heading="Why Choose Us"
    subHeading=""
    description={
      <>
When it comes to legal ghostwriting, precision and expertise are everything, and that is exactly what we bring to the table. Our experienced legal ghostwriters can assist you with a wide range of services, from drafting contracts and legal memos to preparing documents that ensure accuracy, coherence, and full compliance with the latest laws and regulations.
         <br/><br/>
         What sets us apart? Our team does not just write; they bring a fresh perspective, often with specialized knowledge in specific areas of law. This means you get well-crafted, impactful legal content that is tailored to your needs while you focus on what you do best.
      </>
  }
    imageSrc={fictionwhychoose}
    imageHeight=""        // Set height here
    imageWidth=""          // Set width here
    objectfit="cover"          // Set object-fit here
    borderRadius="15px"        // Set border radius here
/>
</div>
<CtaButton/>
         
          <div>
      <FAQSection faqData={faqData} />
    </div>
         <ContactForm/>

      <Footer/>
    </div>
  );
};
export default LegalGhostwriting;


