// src/pages/About.js
import React,{ useState } from 'react';
import BreadCrumb from '../components/common/BreadCrumb';
// import DesigningServices from '../components/Designing/DesigningServices';
import CtaButton from '../components/common/CtaButton';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import Ghostwritingbg from '../images/Ghostwritingbg.png'; // Import the image
import FAQSection from '../components/Designing/DesignFaq';
import ContactForm from '../components/common/ContactForm1';
import SecondSection from '../components/home/SecondSection';
import DesignProcess from '../components/common/DesignProcess';
import ImageRightBanner from '../components/common/ImageRightText';
import ImageLeftBanner from '../components/common/ImageLeftText';
import FictionImage1 from '../images/Adventure_wa.png';
import fictionwhychoose from '../images/fictionwhychoose.png';
import ghostWritingImage from '../images/Ghost Writing.png';
import designingImage from '../images/Designing.png';
import bookEditingImage from '../images/book edit.png';
import designprocess1 from '../images/design-process-1.png';
import designprocess2 from '../images/design-process-2.png';
import designprocess3 from '../images/design-process-3.png';
import designprocess4 from '../images/design-process-4.png';
import PopupForm1 from '../components/common/PopupForm';
import { Helmet } from 'react-helmet-async';

// ******************************** Start SecondSection ******************************

const services = [
  {
    title: "Action-Packed ",
    image: ghostWritingImage,
    description: "Our adventure ghostwriters craft heart-racing stories filled with intense action sequences, keeping readers on the edge of their seats from start to finish."
  },
  {
    title: "Exploration ",
    image: designingImage,
    description: "We bring your vision of thrilling expeditions and mysterious lands to life, weaving captivating narratives of exploration that immerse readers in exciting, uncharted worlds."
  },
  {
    title: "Fantasy ",
    image: bookEditingImage,
    description: "Our team creates enchanting fantasy adventures where magic, mythical creatures, and epic quests come together to captivate the imagination of readers everywhere."
  }
];



// ******************************** End SecondSection ******************************

// ******************************** Start Design Process Props ******************************

const heading = {
  title: (
    <>
     How We Create Your Adventure
     <br />
    </>
  ),
  highlight: "Ghostwriting Masterpiece"
};
// const description = "We follow a proper book cover designing process to ensure the best final product for our clients.";

const steps = [
  {
    image: designprocess1,
    alt: "Idea Exploration",
    title: "Idea Exploration",
    description: "We kick things off with an engaging consultation to discuss your adventure story ideas. Whether it is a thrilling expedition, an epic quest, or a personal journey."
  },
  {
    image: designprocess2,
    alt: "Research & World-Building",
    title: "Research & World-Building",
    description: "Adventure stories thrive on detail and authenticity. Our ghostwriters dive deep into research, gathering insights on locations, cultures, and elements that will enrich your narrative."
  },
  {
    image: designprocess3,
    alt: "Crafting the Narrative",
    title: "Crafting the Narrative",
    description: "With a solid outline in hand, we begin the exciting process of writing your adventure story. Our skilled ghostwriters will craft compelling narratives that capture your unique voice and keep readers engaged. "
  },
  {
    image: designprocess4,
    alt: "Publishing Support",
    title: "Publishing Support",
    description: "Once the manuscript is complete, we will move into the editing phase to polish your adventure story to perfection. "
  }
];


// ******************************** End Design Process Props ******************************

// ******************************** Start FAQ Section ******************************
const faqData = [
  {
    question: "What types of adventure stories do you specialize in?",
    answer: "At AMZ Book Publishings, we love diving into all kinds of adventure tales! Whether you are looking to write an epic fantasy, a gripping travel memoir, or a thrilling survival story, our ghostwriters are ready to help you craft something truly special."
  },
  {
    question: "How involved will I be in the writing process?",
    answer: "We believe that collaboration is key! You will be involved every step of the way. From brainstorming ideas and outlining the plot to providing feedback on drafts, we want to make sure your voice shines through."
  },
  {
    question: "What if I don’t have a clear idea for my adventure story?",
    answer: "No worries! Many of our clients come to us with just a spark of an idea. Our talented ghostwriters are experts at brainstorming and helping you shape your thoughts into a cohesive adventure narrative. We will ask the right questions and guide you through the process until you have a clear direction."
  },
  {
    question: "How long does it take to complete an adventure manuscript?",
    answer: "The timeline can vary based on the complexity of your story and your specific goals. Generally, we aim to complete a full draft within a few months. However, we prioritize quality over speed, so we will ensure you are happy with every detail before moving on to the next stage."
  },
  {
    question: "What makes AMZ Book Publishings stand out for adventure ghostwriting?",
    answer: "Our team is passionate about storytelling and has a knack for weaving captivating narratives that draw readers in. Moreover, we pride ourselves on our affordable ghostwriting services without compromising on quality. With our personalized approach and dedication to your vision, we make the adventure writing process enjoyable and rewarding!"
  }
];

// ******************************** End FAQ Section ******************************

const AdventureGhostwriting = () => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control the modal

  const openModal = () => {
    setIsModalOpen(true); // Open the modal
  };

  const closeModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  return (
    <div>
      <Helmet>
    <title>Adventure Ghostwriting Services by Adventure Ghostwriter</title>
    <meta name="description" content="Need an adventure book ghostwriter? We offer action-adventure writing services at an affordable price. Meet our talented writers who can work on your story." />
    <link rel="canonical" href="https://amzbookpublishing.net/adventure-ghostwriting-services " />
    <meta name="robots" content="index, follow" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="article" />
    <meta property="og:title" content="Adventure Ghostwriting Services by Adventure Ghostwriter" />
    <meta property="og:description" content="Need an adventure book ghostwriter? We offer action-adventure writing services at an affordable price. Meet our talented writers who can work on your story." />
    <meta property="og:url" content="https://amzbookpublishing.net/adventure-ghostwriting-services" />
    <meta property="og:site_name" content="AMZBookPublishing" />
    <meta property="article:publisher" content="https://www.facebook.com/AmzBookPublishingUS" />
    <meta property="article:modified_time" content="2024-08-20T07:37:16+00:00" />
      </Helmet>
      {/* -------------------Start Banner section---------------------- */}
      <Header />
      <BreadCrumb
        title="Adventure Ghostwriting"
        desc={
          <>
           Step into the literary world with a bang using our exceptional adventure ghostwriting services! We have the best ghostwriting services to help you craft a story that stands out and makes a lasting impact!

          </>
        }
        buttonText="Get a Quote"
        onClick={openModal} // Call openModal on button click
        backgroundImage={Ghostwritingbg} // Pass the image URL as a prop
      />

      {/* Popup Form */}
      <PopupForm1 isOpen={isModalOpen} closeModal={closeModal} />
    
   
{/* -------------------End Banner section---------------------- */}

{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<ImageTextBanner 
  
    
    contentItems={contentItems} 
/>
</div> */}


<div style={{ padding: '40px 0px 100px 0px' }}>
<ImageRightBanner
    heading="Adventure "
    subHeading="Ghostwriting"
    description={
        <>
         Adventure has always been a crowd favorite, whether it is in books or films, and our team of experienced adventure fiction ghostwriters is here to bring your creative visions to life. From suspense and drama to action-packed journeys, we know how to craft captivating adventure stories that keep readers hooked. 
           <br /><br />
           Fiction ghostwriting has always led the way in the literary world, and with our talented writers by your side, you will have the chance to collaborate and create something truly unforgettable. Let's turn your adventure ideas into an extraordinary book!

        </>
    }
    imageSrc={FictionImage1}
    objectfit="contain"
    imageHeight="400px"  // Set height here
    imageWidth="100%"     // Set width here
    borderRadius="30px"
/>
</div>

{/*****************  SERVICE Section *****************/}
<div>
<SecondSection 
        buttonLabel="S e r v i c e s"
        heading1={ <>
        Top-Notch  </>}
        subHeading={<>
        Adventure<br/>
        Ghostwriting </>}
        heading2="Services"
        paragraph="Our adventure ghostwriters bring your bold ideas to life, transforming them into captivating stories with characters that spark the imaginations of any and all readers."
        services={services}
      />
      </div>


{/* <div style={{ padding: '0px 0px 30px 0px' }}>
<SecondSection/>
</div> */}


<DesignProcess 
        heading={heading}
        // description={description}
        steps={steps}
      />

<div style={{ padding: '0px 0px 50px 0px' }}>
<ImageLeftBanner
    heading="Why Choose Us"
    subHeading=""
    description={
      <>
       When it comes to bringing your adventure story to life, AMZ Book Publishings is your trusted partner. Our affordable ghostwriting services feature experienced storytellers who understand the thrill of adventure narratives. We take the time to get to know you and your vision, ensuring every element reflects your unique voice. 

       <br /><br />
       With thorough research and world-building, we create immersive experiences that captivate readers. In addition, our commitment to open communication means you will always be in the loop, providing feedback every step of the way. Choosing us means you are not just getting a ghostwriter; you are gaining a dedicated team invested in making your adventure story unforgettable.

      </>

    }
    imageSrc={fictionwhychoose}
    imageHeight=""        // Set height here
    imageWidth=""          // Set width here
    objectfit="cover"          // Set object-fit here
    borderRadius="15px"        // Set border radius here
/>


</div>
<CtaButton/>
         
          <div>
      <FAQSection faqData={faqData} />
    </div>
         <ContactForm/>

      <Footer/>
    </div>
    
  );
};
export default AdventureGhostwriting;


