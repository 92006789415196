


const CtaButtons = () => {

 
    return (
    
<div className="btn-wa">
<a href="tel:+17327979165" className="cta-btn">Call Us</a> 

</div>
                           
    );
};

export default CtaButtons;